import React, { Component } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import backgroundImg from '../../../assets/images/hero_image_v2_ins.png';

export default class WelcomeSection extends Component {
  state = {};

  showPatientVideo = () => {
    this.setState({
      showVideoModal: true,
      videoUrl: 'https://www.youtube.com/embed/UGoTWUuJTEM?rel=0&autoplay=1',
    });
  };

  showProviderVideo = () => {
    this.setState({
      showVideoModal: true,
      videoUrl: 'https://www.youtube.com/embed/tSqqYWs7hb8?rel=0&autoplay=1',
    });
  };

  closeVideoModal = () => {
    this.setState({
      showVideoModal: false,
    });
  };

  render() {
    return (
      <div className='col-md-6 hidden-sm hidden-xs image-and-process-container no-padding'>
        <div className='width-100'>
          <img className='width-100' src={backgroundImg} alt='background' />
          {/* <div className="col-xs-6 header-content float-right">
            Knowing
            <br />
            what&apos;s ahead.
            <br />
            That&apos;s powerful.
          </div> */}
        </div>
        <div className='col-xs-12 portal-container'>
          <div className='patient-learn-more-container'>
            <div>
              <i className='icon icon-font-a-profile'></i>
            </div>
            <div className='content'>
              <span>
                As a patient or caregiver, INSIGHTEC Connect gives you
                visibility and tools to manage the process that lies ahead.
              </span>
            </div>
          </div>
          <div className='provider-learn-more-container'>
            <div>
              <i className='icon icon-font-a-stethoscope'></i>
            </div>
            <div className='content'>
              <span>
                As a provider, INSIGHTEC Connect gives you visibility and tools
                to navigate which patients are on-and-off-track so you can
                intervene to help them achieve healthy outcomes.
              </span>
            </div>
          </div>
        </div>

        <Modal
          show={this.state.showVideoModal}
          onHide={this.closeVideoModal}
          container={document.body}
          aria-labelledby='contained-modal-title'
          className='primary-modal custom-video-container'
        >
          <Modal.Body>
            <iframe
              title='learn-patient-provider-video-url'
              id='video'
              src={this.state.videoUrl}
              frameBorder='0'
              allow='autoplay; encrypted-media'
              allowFullScreen
            ></iframe>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
